export const usePanelLinks = function() {
  const runtimeConfig = useRuntimeConfig()
  const { t } = useI18n()
  const registerUrl = computed( () => runtimeConfig.public.panelUrl + t('links.register').toString() )
  const loginUrl = computed( () => runtimeConfig.public.panelUrl + t('links.login').toString() )

  return {
    loginUrl,
    registerUrl,
  }
}